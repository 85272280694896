$(document).ready(function(){
    $('.afs-solution-teaser .owl-carousel').owlCarousel({
        loop:false,
        stagePadding: 0,
        autoWidth:false,
        margin: 29,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            555:{
                items:2
            },
            847:{
                items:3
            },
            1139:{
                items:4
            }
        }
    })
});